import { useContext } from 'react'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import { useNavigate } from 'react-router-dom'
import { AuthContext } from '../contexts/authContext'
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import HomeIcon from "@mui/icons-material/Home";

function deleteAllCookies() {
  var cookies = document.cookie.split(";");

  for (var i = 0; i < cookies.length; i++) {
    var cookie = cookies[i];
    var eqPos = cookie.indexOf("=");
    var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
  }
}

export const NavBar = () => {
  const auth = useContext(AuthContext)
  const history = useNavigate()

  function signOutClicked() {
    deleteAllCookies();
    auth.signOut();
    history('/');
  }

  return (
    <Box
      width="100%"
      sx={{
        display: "flex",
        flexDirection: "row",
        p: 1,
        bgcolor: "background.paper",
        borderRadius: 1,
      }}
    >
      <Box sx={{ ml: 1, mr: 1 }}>
        <Button
          onClick={() => history("/")}
          variant="contained"
          color="primary"
        >
          <HomeIcon />
          Home
        </Button>
      </Box>
      <Box sx={{ ml: 1, mr: 1 }}>
        <Button
          onClick={() => history("/account")}
          variant="contained"
          color="primary"
        >
          <ManageAccountsIcon /> Account
        </Button>
      </Box>
      <Box style={{ marginLeft: "auto" }} justifyContent="flex-end">
        <Button onClick={signOutClicked} variant="outlined">
          Logout
        </Button>
      </Box>
    </Box>
  );
}
