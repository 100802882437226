const NAMED_COLORS: { [key: string]: any } = {
  bitcoin: "#ffa500",
  ethereum: "#407aff",
  litecoin: "#00ff15",
  storjcoin: "#376FFF"
};

export const COLORS: { [key: string]: any } = {
  btc: NAMED_COLORS.bitcoin,
  btceur: NAMED_COLORS.bitcoin,
  btcusd: NAMED_COLORS.bitcoin,
  eth: NAMED_COLORS.ethereum,
  ethusd: NAMED_COLORS.ethereum,
  etheur: NAMED_COLORS.ethereum,
  comparison: "grey",
  ltcusd: NAMED_COLORS.litecoin,
  ltc: NAMED_COLORS.litecoin,
  primary: "#4caf50",
  linkusd: "#375BD2",
  filusd: "#0090ff",
  storjusd: NAMED_COLORS.storjcoin,
  uniusd: "#ff007a",

  // binance
  ETHUSDT: NAMED_COLORS.ethereum,
  LTCUSDT: NAMED_COLORS.litecoin,
  BTCUSDT: NAMED_COLORS.bitcoin,
};
