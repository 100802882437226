import { useContext, useMemo, useState } from "react";
import { AuthContext } from "../contexts/authContext";
import { getPortfolio } from "../libs/api";
import {
  PieChart,
  Pie,
  Cell,
} from "recharts";
import { COLORS } from "../helpers/colors";
import { PriceContext } from "../contexts/priceContext";
import { Box, Flex, Text } from "rebass";
import { Table } from "./Table";
import { Loading } from "./loading";

export const Portfolio = ({
  selectedExchange,
}: {
  selectedExchange: string;
}) => {
  const auth = useContext(AuthContext);
  const priceContext: { [key: string]: any } = useContext(PriceContext);
  const [portfolio, setPortfolio] = useState<{
    [exchange: string]: {
      [key: string]: {
        current_value: number;
        amount: number;
        average_price: number;
        total_spend: number;
        profit_loss_dollars: number;
        profit_loss_percent: number;
      };
    };
  }>();
  const [isFetching, setIsFetching] = useState(false);

  useMemo(async () => {
    if (Object.entries(auth?.sessionInfo as {}).length === 0) {
      return;
    }

    if (!portfolio && !isFetching) {
      setIsFetching(true);
      const portfolio = await getPortfolio(selectedExchange);

      if (portfolio) {
        setPortfolio(portfolio);
      }
      setIsFetching(false);
    }
  }, [auth?.sessionInfo, portfolio, isFetching, selectedExchange]);

  if (!auth) {
    return null;
  }

  if (!portfolio) {
    return null;
  }

  if (!priceContext) {
    return null;
  }

  if (!selectedExchange) {
    return null;
  }

  if (!priceContext.prices[selectedExchange]) {
    return null;
  }

  const data =
    portfolio[selectedExchange] &&
    Object.entries(portfolio[selectedExchange])
      ?.map(([ticker, stats]) => ({ ...stats, value: stats.current_value, name: ticker }))
      .sort((a, b) => (a.current_value < b.current_value ? 1 : -1));

  if (!data) {
    return <Box>No portfolio yet - it'll show up once a limit order executes.</Box>;
  }

  if (isFetching) {
    return <Loading />
  }
  const totalSpend = data
    .map((coin) => coin.total_spend)
    .reduce((prevValue, currentValue) => (prevValue += currentValue));
  const totalValue = data.map(coin => coin.value).reduce((prevValue, currentValue) => prevValue += currentValue);
  const totalProfitOrLossInDollars = data
    .map((coin) => coin.profit_loss_dollars)
    .reduce((prevValue, currentValue) => (prevValue += currentValue));
  const totalProfitOrLossInPercent =
    (totalProfitOrLossInDollars / totalSpend) * 100;

  return (
    <Box
      sx={{
        backgroundColor: "rgb(0 0 0 / 16%)",
        width: "100%",
      }}
      p={4}
    >
      <Box margin="0 auto" width={["100%", "800px"]}>
        <Text as="h2">Portfolio</Text>
        <Text mb="2">
          This is pulled from your trade history, not your active balance on the
          exchange.
        </Text>
        <Flex flexDirection={["column"]}>
          <Box margin="0 auto">
            <Flex flexDirection={["row"]}>
              <Box mr="4" mt="4">
                <Text as="h3">Current Value</Text>
                <Text as="h1">${Math.round(totalValue).toLocaleString()}</Text>
              </Box>
              <Box mr="4" mt="4">
                <Text as="h3">Profit or Loss</Text>
                <Text as="h1">
                  ${Math.round(totalProfitOrLossInDollars).toLocaleString()}
                </Text>
              </Box>
              <Box mr="4" mt="4">
                <Text as="h1" mt="1.5rem">
                  {Math.round(totalProfitOrLossInPercent).toLocaleString()}%
                </Text>
              </Box>
            </Flex>
          </Box>
          <Box margin="0 auto">
            {/* <ResponsiveContainer width="100%" height={400}> */}
            <PieChart width={400} height={400}>
              <Pie
                data={data}
                cx="50%"
                cy="50%"
                outerRadius={100}
                dataKey="value"
                label={(entry) => entry.name}
              >
                {data.map((entry) => (
                  <Cell
                    key={`cell-${entry.name}`}
                    fill={
                      COLORS[entry.name.toLowerCase().replace('-', '')] ? `${COLORS[entry.name.toLowerCase().replace('-', '')]}90` : "#bab3b3"
                    }
                  />
                ))}
              </Pie>
            </PieChart>
            {/* </ResponsiveContainer> */}
          </Box>
          {data && (
            <Box mt={[0, 5]} ml="auto" mr="auto">
              <Table
                headers={[
                  "ticker",
                  "amount",
                  "average price",
                  "current value",
                  "total spend",
                  "profit/loss",
                  "%",
                ]}
                rows={data
                  .filter((row) => row.amount > 0)
                  .map((row) => {
                    const ticker = row.name;
                    const amountOfCoins = row.amount;
                    const averagePrice = row.average_price;
                    const currentValue = row.current_value;
                    const totalSpend = row.total_spend;
                    const profitOrLossInDollars = row.profit_loss_dollars;
                    const profitOrLossInPercent = row.profit_loss_percent;

                    return [
                      ticker,
                      amountOfCoins.toFixed(8),
                      `$${averagePrice.toLocaleString()}`,
                      `$${currentValue.toLocaleString()}`,
                      `$${totalSpend.toLocaleString()}`,
                      `$${profitOrLossInDollars.toLocaleString()}`,
                      `${profitOrLossInPercent.toFixed()}%`,
                    ];
                  })}
              />
            </Box>
          )}
        </Flex>
      </Box>
    </Box>
  );
};
