import React from 'react'

import { useNavigate } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'

import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import { COLORS } from '../helpers/colors'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  title: {
    textAlign: 'center',
  },
}))

const Landing: React.FunctionComponent = () => {
  const classes = useStyles()

  const history = useNavigate()

  const signIn = () => {
    history('/signin')
  }

  return (
    <Grid container>
      <Grid
        className={classes.root}
        container
        direction="column"
        justify="center"
        alignItems="center"
      >
        <Box m={2}>
          <Grid container direction="column">
            <Typography
              className={classes.title}
              style={{
                fontSize: "4rem",
                fontWeight: "bold",
                color: COLORS.primary,
              }}
            >
              buy the dip
            </Typography>
            <Typography className={classes.title} variant="h2">
              bot
            </Typography>
          </Grid>
        </Box>
        <Box maxWidth="400px" ml={2} mt={6}>
          <Typography variant="h6">
            I keep limit-buy orders open to make sure you get the most for your money.
          </Typography>
        </Box>
        <Box mt={6}>
          <Typography>
            <strong>This is alpha software with no guarantees.</strong>
          </Typography>
        </Box>
        <Box m={2}>
          <Button
            onClick={() => history("/how-it-works")}
            variant="outlined"
            color="primary"
            style={{ marginRight: "1rem" }}
          >
            HOW IT WORKS
          </Button>
          <Button data-test="go-to-login-page-button" onClick={signIn} variant="contained" color="primary">
            SIGN IN
          </Button>
        </Box>

        <Box mt={4}>
          <Button
            onClick={() => history("/in-the-news")}
            variant="outlined"
            style={{ marginRight: "1rem" }}
          >
            In the news
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
}

export default Landing
