import React from "react";
import { useNavigate } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import { Card } from "@material-ui/core";

const NewsItem = ({
  title,
  body,
  url,
}: {
  title: string;
  body: string;
  url: string;
}) => (
  <Box m={2} maxWidth="500px">
    <Card>
      <Box p={2}>
        <Typography variant="h4">{title}</Typography>
        <Typography>{body}</Typography>
        <Box mt={2}>
          <Button variant="outlined" onClick={() => window.open(url)}>
            View article
          </Button>
        </Box>
      </Box>
    </Card>
  </Box>
);

export default function InTheNews() {
  const history = useNavigate();

  return (
    <Grid container>
      <Box maxWidth="1000px" m={"0 auto"}>
        <Box ml={2} mt={2} mb={5}>
          <Button
            color="primary"
            variant="contained"
            onClick={() => history(-1)}
          >
            GO BACK
          </Button>
        </Box>
        <Grid container direction="column" justify="center" alignItems="center">
          <Typography variant="h2">DipRobot in the news</Typography>

          <NewsItem
            title="Trader builds Bitcoin ‘buy the dip’ bot, outperforms DCA"
            body="A Redditor has created an automated dip-buying bot that beats
              dollar-cost averaging into Bitcoin by roughly 10%."
            url="https://cointelegraph.com/news/trader-builds-bitcoin-buy-the-dip-bot-outperforms-dca"
          />

          <NewsItem
            title="Buy the dip crypto trading bot outperforms DCA trading strategy by 10%"
            body="A trading bot has been determining the best Bitcoin entry points and performing limit buys of the asset since December."
            url="https://www.crypto-news-flash.com/buy-the-dip-crypto-trading-bot-outperforms-dca-trading-strategy-by-10/"
          />

          <NewsItem
            title="Trader Develops a ‘Buy the Dip’ Bitcoin Bot"
            body="While another Reddit user opted for a manual limit-buy-order strategy in order to find the best price, the creator of the bot took it up a notch and decided to code an automated bot focused on doing just that. "
            url="https://crypto-academy.org/trader-develops-a-buy-the-dip-bitcoin-bot/"
          />

          <NewsItem
            title="Trader builds Bitcoin ‘buy the dip’ bot, outperforms DCA"
            body="While a bullish backdrop emerges in February, spare a thought for the traders trying to time the market."
            url="https://www.investing.com/news/cryptocurrency-news/trader-builds-bitcoin-buy-the-dip-bot-outperforms-dca-2759284"
          />

          <Box mt={4}>
            <Button
              color="primary"
              variant="outlined"
              onClick={() =>
                window.open(
                  "mailto:admin@diprobot.com?subject=about%20the%20bot"
                )
              }
            >
              Contact
            </Button>
            <Box mt={4} mb={5}>
              <Button
                color="primary"
                variant="contained"
                onClick={() => history(-1)}
              >
                GO BACK
              </Button>
            </Box>
          </Box>
        </Grid>
      </Box>
    </Grid>
  );
}
