import { Box, CardMedia, Button } from "@material-ui/core";

export const ExchangeButton = ({
  exchangeName,
  image,
  onClick,
  isActive = false,
}: {
  exchangeName: string;
  image: string;
  onClick: () => void;
  isActive?: boolean;
}) => {
  return (
    <Box mb={2} data-test={`setup-${exchangeName}-button`}>
      <Button
        onClick={onClick}
        variant="outlined"
        style={{
          width: "100%",
          transition: "2s all",
          cursor: "pointer",
          padding: "2rem",
          backgroundColor: isActive ? "#4caf50" : "none",
        }}
      >
        <CardMedia
          component="img"
          image={image}
          alt={`${exchangeName} logo`}
          style={{
            transition: "2s all",
            filter: isActive ? "grayscale(1)" : "none",
          }}
        />
      </Button>
    </Box>
  );
};
