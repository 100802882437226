import { ThreeDots } from "react-loading-icons";
import { Box } from "rebass";

export const Loading = () => {
    return (
      <Box m="0 auto" p={4}>
        <ThreeDots />
      </Box>
    );
};
