import { useContext, useEffect, useState } from "react";
import { Box, Text, Flex } from "rebass";
import {
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  ScatterChart,
  Scatter,
  ReferenceLine,
} from "recharts";
import {
  cancelOpenOrders,
  getOpenOrders,
  getTradeHistory,
  getDollarsSpentLastNumDays,
} from "../libs/api";
import { COLORS } from "../helpers/colors";
import { usePrevious } from "../helpers/usePrevious";
import { Table } from "../components/Table";
import { Link } from "react-router-dom";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import { TabPanel } from "../components/tabs";
import { Button } from "@material-ui/core";
import { getTickerInfo } from "../helpers/tickers";
import { UserConfigContext } from "../contexts/userConfigContext";
import { PriceContext } from "../contexts/priceContext";
import { Loading } from "./loading";

const StatsPanel = ({
  selectedExchange,
  trades,
  stats,
  ticker,
  currentPrice,
}: {
  selectedExchange: string;
  trades: { price: string; amount: number; datetime: string }[];
  stats: { [key: string]: any };
  ticker: string;
  currentPrice: number;
}) => {
  const [amountSpent, setAmountSpent] = useState(0);

  const totalNumberOfCoins = Object.values(trades).reduce(
    (t, { amount }) => t + parseFloat(amount as unknown as string),
    0
  );

  useEffect(() => {
    async function fetchDollarsSpentLastNumDays() {
      const amountSpent = await getDollarsSpentLastNumDays(
        ticker,
        selectedExchange,
        1
      );
      setAmountSpent(amountSpent);
    }

    if (!amountSpent) {
      fetchDollarsSpentLastNumDays();
    }
  }, [amountSpent, selectedExchange, ticker]);

  return (
    <Box mb={3}>
      <Text>amount spent in the last day: ${Math.round(amountSpent)}</Text>
      <Text>amount of coins: {totalNumberOfCoins.toFixed(8)}</Text>
      <Text>run time: {stats.daysSinceFirstPurchase} days</Text>
      <Text>average time between trades: {stats.averageNumberOfDaysBetweenTrades} days</Text>
      <Text>days since last trade: {stats.daysSinceLastPurchase}</Text>
    </Box>
  );
};

export const OrderChart = ({
  ticker,
  name,
  trades,
  orders,
  currentPrice,
  configuration,
  selectedExchange,
}: {
  selectedExchange: string;
  ticker: string;
  name: string;
  trades:
    | {
        stats: { [key: string]: any };
        trades: any[];
      }
    | undefined;
  orders: any;
  currentPrice: number;
  configuration: { [key: string]: any };
}) => {
  const [showBuyHistory, setShowBuyHistory] = useState(true);
  const averagePrice = trades?.stats?.averagePrice;
  const yaxisDomain = orders?.length > 0 ? [orders[0]?.price, orders[orders.length - 1]?.price] : null;

  return (
    <Flex
      data-test="order-chart"
      key={ticker}
      width={1}
      flexDirection={["column", "row"]}
    >
      <Box width={1}>
        <Box mb="2" width={1}>
          <Text as="h3" mb="2" color={COLORS[ticker]}>
            {name}
          </Text>
          <Box mt="2" mb="2">
            <Button
              variant="outlined"
              onClick={() => setShowBuyHistory(!showBuyHistory)}
            >
              {showBuyHistory ? "Hide" : "Show"} buy history
            </Button>
          </Box>

          <ResponsiveContainer width="100%" height={400}>
            <ScatterChart
              data={showBuyHistory ? trades?.trades : []}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid stroke="#333" />
              <Tooltip />
              <Legend />
              {currentPrice && (
                <ReferenceLine
                  isFront
                  y={currentPrice}
                  stroke={COLORS[ticker]}
                  ifOverflow="extendDomain"
                  label={{
                    value: `current price - $${currentPrice.toLocaleString()}`,
                    fill: "white",
                  }}
                  color="white"
                  z={2}
                />
              )}
              {trades?.stats && (
                <ReferenceLine
                  isFront
                  key={averagePrice}
                  y={averagePrice}
                  stroke={"grey"}
                  ifOverflow="extendDomain"
                  label={{
                    value: `your average price - $${averagePrice.toLocaleString()}`,
                    fill: "white",
                  }}
                  color="white"
                  z={2}
                />
              )}
              {orders &&
                orders.map((order: any) => (
                  <ReferenceLine
                    isFront
                    key={order.price}
                    y={order.price}
                    stroke={"#4caf50"}
                    ifOverflow="extendDomain"
                    label={{
                      value: `open limit-buy order`,
                      fill: "white",
                    }}
                    color="white"
                    z={2}
                  />
                ))}
              <Scatter
                name="filled orders"
                dataKey="price"
                fill="#ffffff78"
                stroke="grey"
              />
              <XAxis dataKey="datetime" stroke="#ebebeb" />
              <YAxis stroke="#ebebeb" domain={yaxisDomain || undefined} />
            </ScatterChart>
          </ResponsiveContainer>
        </Box>
      </Box>

      <Box key={ticker} ml="2" width={[1, 1 / 2]}>
        {orders?.length > 0 && (
          <>
            <Box mt={"5rem"} mb={2}>
              <Table
                headers={["Placed on", "Price", "Amount", "Total"]}
                rows={orders.map(
                  (trade: {
                    datetime: any;
                    price: string;
                    remaining_amount?: any;
                    amount?: number;
                  }) => [
                    trade.datetime,
                    `$${parseFloat(trade.price).toLocaleString()}`,
                    trade.remaining_amount || trade.amount,
                    `$${(
                      parseFloat(trade.price) *
                      (trade.remaining_amount || trade.amount)
                    ).toLocaleString()}`,
                  ]
                )}
              />
            </Box>
            <Box mb={4}>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() =>
                  cancelOpenOrders(ticker, selectedExchange).then(() =>
                    window.location.reload()
                  )
                }
              >
                Cancel Orders
              </Button>
            </Box>
          </>
        )}
        <Box mt={4}>
          {configuration &&
            Object.entries(configuration).map(
              ([property, value]: [string, any]) => (
                <Box key={property}>
                  <Text>
                    {property.replaceAll("_", " ")}: {value.toString() as any}
                  </Text>
                </Box>
              )
            )}
        </Box>
        <Box>
          {trades && (
            <StatsPanel
              selectedExchange={selectedExchange}
              trades={trades.trades}
              stats={trades.stats}
              ticker={ticker}
              currentPrice={currentPrice}
            />
          )}
        </Box>
      </Box>
    </Flex>
  );
};
const OpenOrders = ({
  selectedExchange,
}: {
  selectedExchange: string;
}) => {
  const prevSelectedExchange = usePrevious(selectedExchange);
  const hasSwitchedExchanges = prevSelectedExchange !== selectedExchange;
  
  const userConfig: { [key: string]: any } = useContext(UserConfigContext);
  const priceContext: { [key: string]: any } = useContext(PriceContext);
  const prices: { [key: string]: any } = priceContext.prices[selectedExchange];
  const [orders, setOrders] = useState<any>();
  const [showBuyHistory, setShowBuyHistory] = useState(false);
  const [isFetchingOrders, setIsFetchingOrders] = useState(false);
  const [isFetchingTradeHistory, setIsFetchingTradeHistory] = useState(false);
  const [tradingPairs, setTradingPairs] =
    useState<{ ticker: string; name: any }[]>();
  const [hasConnectionError, setHasConnectionError] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedTicker, setSelectedTicker] = useState("");
  const [filterByEnabled, setFilterByEnabled] = useState(
    localStorage.getItem("filterByEnabled") !== "false"
  );
  const [trades, setTrades] = useState<{
    [key: string]: {
      stats: { [key: string]: any };
      trades: any[];
    };
  }>();
  const enabledTradingPairs = tradingPairs?.filter((pair) =>
    filterByEnabled
      ? userConfig.userConfiguration[selectedExchange][pair.ticker]?.enabled ===
        true
      : pair
  );

  useEffect(() => {
    // for some reason, this useEffect isn't getting to the fetchTradingPairs block.
    // TODO: figure out why
    
    console.log('in useeffect')
    console.log('hasSwitchedExchanges', hasSwitchedExchanges)
    // Reset state if exchange has been switched
    // if (hasSwitchedExchanges) {
    //   setTradingPairs(undefined);
    //   setOrders(undefined);
    //   setIsFetchingOrders(false);
    //   setIsFetchingTradeHistory(false);
    //   setSelectedTab(0);
    //   setSelectedTicker("");
    //   setTrades(undefined);
    //   setHasConnectionError(false);
    //   return;
    // }
  
    // Fetch trading pairs
    async function fetchTradingPairs() {
      try {
        console.log('fetchTradingPairs')
        const configForExchange = userConfig.userConfiguration[selectedExchange];
        const pairs = Object.entries(configForExchange).map(([key]) => getTickerInfo(key));
        setTradingPairs(pairs);
      } catch (e) {
        console.error("No config found for user");
      }
    }
  
    // Fetch trade history
    async function fetchTradeHistory() {
      if (!enabledTradingPairs) {
        return;
      }
      try {
        const result = await getTradeHistory({ tickers: enabledTradingPairs.map(pair => pair.ticker), selectedExchange });
        if (!result) {
          return;
        }
        setTrades(result);
        setIsFetchingTradeHistory(false);
      } catch (e) {
        console.error(e);
        setHasConnectionError(true);
      }
    }
  
    // Fetch open orders
    async function fetchOpenOrders(tradingPairs: any[]) {
      try {
        const result = await getOpenOrders({ tickers: tradingPairs.map(pair => pair.ticker), selectedExchange });
        setOrders(result);
        setIsFetchingOrders(false);
      } catch (e) {
        console.error(e);
        setOrders({});
        setIsFetchingOrders(false);
        setHasConnectionError(true);
      }
    }
    
    // Return if user configuration is empty
    if (Object.entries(userConfig.userConfiguration).length === 0) {
      console.error("no user configuration; cannot get any trading data");
      return;
    }
    // Set default selected ticker if not set
    if (!selectedTicker && enabledTradingPairs && enabledTradingPairs.length > 0) {
      const defaultSelectedTicker = enabledTradingPairs[0].ticker;
      setSelectedTicker(defaultSelectedTicker);
    }
  
    // Fetch open orders if not fetching already and there are trading pairs
    if (
      (!isFetchingOrders && enabledTradingPairs && hasSwitchedExchanges) ||
      (!isFetchingOrders && !orders && enabledTradingPairs)
    ) {
      setIsFetchingOrders(true);
      fetchOpenOrders(enabledTradingPairs);
    }
  
    // Fetch trading pairs if not set or exchange has been switched
    if (!tradingPairs || hasSwitchedExchanges) {
      fetchTradingPairs();
    }
  
    // Fetch trade history if not fetching already and there are trading pairs
    if (
      (!isFetchingTradeHistory && hasSwitchedExchanges && enabledTradingPairs) ||
      (!isFetchingTradeHistory && !trades && enabledTradingPairs)
    ) {
      setIsFetchingTradeHistory(true);
      fetchTradeHistory();
    }
  
    // Cleanup function for intervals (if any)
    // const intervalId = setInterval(() => {
    //   if (tradingPairs) {
    //     fetchOpenOrders(tradingPairs);
    //     document.title = `$${prices[selectedTicker]} - Buy the Dip bot`
    //   }
    // }, 30000);
    // return () => clearInterval(intervalId);
  }, [
    orders,
    setOrders,
    tradingPairs,
    setTradingPairs,
    setHasConnectionError,
    userConfig,
    selectedExchange,
    hasSwitchedExchanges,
    setSelectedTab,
    trades,
    prices,
    selectedTicker,
    enabledTradingPairs,
    isFetchingOrders,
    isFetchingTradeHistory,
    
  ]);


  // if (!orders || Object.entries(prices).length === 0) {
  //   return <Loading />;
  // }

  if (hasConnectionError) {
    return (
      <>
        <Text as="h1" mt="4">
          Error loading open orders.{" "}
        </Text>
        <Text as="h2">
          First, try reloading. If that doesn't work,
          <Link to="/account">go here to edit your API keys.</Link>
        </Text>
      </>
    );
  }

  if (isFetchingOrders) {
    return <Loading />
  }
  
  return (
    <>
      <Box width={1} flexDirection={["column", "row"]}>
        <Box mb={2}>
          <Button
            variant={filterByEnabled ? "contained" : "outlined"}
            color="primary"
            onClick={() => {
              setFilterByEnabled(!filterByEnabled);
              localStorage.setItem(
                "filterByEnabled",
                `${!filterByEnabled || false}`
              );
            }}
          >
            {filterByEnabled ? "clear Enabled filter" : "Filter by enabled"}
          </Button>
        </Box>
        <Tabs
          value={selectedTab}
          onChange={(event, newValue) => setSelectedTab(newValue)}
          aria-label="basic tabs example"
        >
          {prices &&
            enabledTradingPairs?.map(
              (pair: { ticker: string; name: string }, index: number) => (
                <Tab
                  key={pair.name}
                  label={pair.name}
                  onClick={() => setSelectedTicker(pair.ticker)}
                />
              )
            )}
        </Tabs>
        {prices &&
          tradingPairs
            ?.filter((pair) =>
              filterByEnabled
                ? userConfig.userConfiguration[selectedExchange][pair.ticker]
                    ?.enabled === true
                : pair
            )
            .map((pair: { ticker: string; name: string }, index: number) => (
              <TabPanel key={pair.ticker} value={selectedTab} index={index}>
                <Text textAlign="left" ml="2" mb="4" fontSize="1rem">
                  If the asset's{" "}
                  <span style={{ fontWeight: "bold" }}>price</span> drops down
                  to any of the{" "}
                  <strong>green lines (open limit-buy orders)</strong>, the buy
                  order gets filled. Then, I create new orders based on the new
                  price.
                </Text>
                <OrderChart
                  ticker={pair.ticker}
                  name={pair.name}
                  trades={trades && trades[pair.ticker]}
                  orders={orders && orders[pair.ticker]}
                  currentPrice={prices && prices[pair.ticker]}
                  configuration={
                    userConfig.userConfiguration[selectedExchange][pair.ticker]
                  }
                  selectedExchange={selectedExchange}
                />
                <Button
                  variant="outlined"
                  onClick={() => setShowBuyHistory(!showBuyHistory)}
                >
                  {showBuyHistory ? "Hide" : "Show"} list of filled orders
                </Button>
                {showBuyHistory &&
                  prices &&
                  trades &&
                  pair.ticker in trades &&
                  trades[pair.ticker].trades.length > 0 && (
                    <Box key={pair.ticker} width={[1, 1 / 2]} ml={4}>
                      <Text as="h3" mb={2} mt={2}>
                        Buy History
                      </Text>
                      <Table
                        headers={[
                          "Date",
                          "Price",
                          "Amount",
                          "Value",
                          "Gain/Loss",
                        ]}
                        rows={
                          pair.ticker in trades &&
                          Object.values(trades[pair.ticker].trades)
                            .reverse()
                            .map((trade) => [
                              trade.datetime,
                              `$${parseFloat(trade.price).toLocaleString()}`,
                              trade.amount,
                              `$${Math.round(
                                prices[pair.ticker] * trade.amount
                              )}`,
                              `$${Math.round(
                                prices[pair.ticker] * trade.amount -
                                  parseFloat(trade.price) * trade.amount
                              )}`,
                            ])
                        }
                      />
                    </Box>
                  )}
              </TabPanel>
            ))}
      </Box>
    </>
  );
};


export default OpenOrders;