import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";

import { NavBar } from "../components/navbar";
import { ExchangeButton } from "../components/exchangeButton";
import SettingsIcon from "@mui/icons-material/Settings";
import PasswordIcon from "@mui/icons-material/Password";
import { getUserConfiguration } from "../libs/api";

const useStyles = makeStyles((theme) => ({
  root: {},
  title: {
    textAlign: "center",
  },
  session: {
    width: "80vw",
    overflow: "auto",
    overflowWrap: "break-word",
    fontSize: "16px",
  },
  hero: {
    width: "100%",
    background: "#333",
  },
}));

export default function Account() {
  const classes = useStyles();
  const [userConfiguration, setUserConfiguration] = useState({});
  const history = useNavigate();

  function changePasswordClicked() {
    history("changepassword");
  }

  useEffect(() => {
    async function fetchUserConfiguration() {
      try {
        const userConfiguration = await getUserConfiguration();
        setUserConfiguration(userConfiguration);
      } catch (e) {
        console.error("No config found for user");
      }
    }
    fetchUserConfiguration();
  }, []);

  const coinbaseIsActive = Object.keys(userConfiguration).includes("coinbase");
  const getminiIsActive = Object.keys(userConfiguration).includes("gemini");
  const binanceIsActive = Object.keys(userConfiguration).includes("binance");

  return (
    <Grid container>
      <NavBar />
      <Grid
        className={classes.root}
        container
        direction="column"
        justify="center"
        alignItems="center"
      >
        <Grid
          className={classes.root}
          container
          direction="column"
          justify="center"
          alignItems="center"
        >
          <Box maxWidth="500px">
            <Box onClick={() => history("/configuration")} m={2}>
              <Card>
                <Box p={2}>
                  <Box mb={2}>
                    <Typography variant="h6">
                      Want to change which coins are purchased and set up daily
                      limits?
                    </Typography>
                  </Box>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ margin: "0 auto", display: "block" }}
                  >
                    <Box>
                      <SettingsIcon />
                    </Box>
                    Coin setup
                  </Button>
                </Box>
              </Card>
            </Box>
            <Box m={2}>
              <Card>
                <Box p={2}>
                  <Box mb={2}>
                    <Typography variant="h6">
                      Need to change your password?
                    </Typography>
                  </Box>
                  <Button
                    onClick={changePasswordClicked}
                    variant="outlined"
                    color="secondary"
                    style={{ margin: "0 auto", display: "block" }}
                  >
                    <Box>
                      <PasswordIcon />
                    </Box>
                    Change Password
                  </Button>
                </Box>
              </Card>
            </Box>

            <Box mt={4} mb={2} ml={2}>
              <Typography variant="h4">Update Exchange Account</Typography>
            </Box>
            <Box m={2}>
              <ExchangeButton
                isActive={coinbaseIsActive}
                exchangeName="coinbase"
                onClick={() => history("/coinbase")}
                image="images/coinbase-logo.png"
              />
            </Box>
            {/* <Box m={2}>
              <ExchangeButton
                isActive={getminiIsActive}
                exchangeName="gemini"
                onClick={() => history("/gemini")}
                image="images/gemini-logo.png"
              />
            </Box>
            <Box m={2}>
              <ExchangeButton
                isActive={binanceIsActive}
                exchangeName="binance"
                onClick={() => history("/binance")}
                image="images/binance-logo.png"
              />
            </Box> */}
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
}
