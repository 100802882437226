import { Box} from 'rebass'


export function TabPanel(props: { [x: string]: any; children: any; value: any; index: any }) {
  const { children, value, index, ...other } = props;
  return (
    <div
      style={{
        backgroundColor: "rgb(0 0 0 / 16%)",
      }}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box m="2rem auto" p={4}>{children}</Box>}
    </div>
  );
}