import React, {  useState, useEffect, useContext, useMemo } from "react";

import { useNavigate } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import { NavBar } from "../components/navbar";

import { checkCoinbaseApiKey } from "../libs/api";
import { Loading } from "../components/loading";
import { AuthContext } from "../contexts/authContext";
import { Footer } from "../components/footer";
import { UserConfigContext } from "../contexts/userConfigContext";
import { Portfolio } from "../components/portfolio";
import OpenOrders from "../components/openOrders";

 const useStyles = makeStyles((theme) => ({
  root: {},
  title: {
    textAlign: "center",
  },
  session: {
    width: "80vw",
    overflow: "auto",
    overflowWrap: "break-word",
    fontSize: "16px",
  },
  hero: {
    width: "100%",
    background: "#333",
  },
}));

async function fetchHasSetupKeys(exchangeName: "gemini" | "binance" | "coinbase") {
  try {
    if (exchangeName === "coinbase") {
      const hasSetupCoinbase = await checkCoinbaseApiKey();
      return hasSetupCoinbase.has_key;
    }
    return false;
  } catch (e) {
    console.error("error checking if user has set up API keys", e);
    return false;
  }
}

export default function Home() {
  const auth = useContext(AuthContext);
  const userConfiguration = useContext(UserConfigContext);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [hasSetupCoinbase, setHasSetupCoinbase] = useState<boolean>();
  
  useMemo(async () => {
    if (isLoading && Object.keys(userConfiguration).length > 0) {
      const hasSetupCoinbase = await fetchHasSetupKeys("coinbase");
      setHasSetupCoinbase(hasSetupCoinbase);

      setIsLoading(false);
    }
  }, [isLoading, userConfiguration]);

  const [selectedExchange, setSelectedExchange] = useState<string | null>(localStorage.getItem("selectedExchange") || null);

  const classes = useStyles();

  const history = useNavigate();
  const exchanges = Object.keys(userConfiguration.userConfiguration);
  const hasSetupAnExchange = hasSetupCoinbase === true;

  useEffect(() => {
    if (Object.entries(auth?.sessionInfo as {}).length === 0) {
      return;
    }
    if (exchanges && !selectedExchange) {
      setSelectedExchange(exchanges[0]);
    }
  }, [auth, hasSetupCoinbase, exchanges, selectedExchange]);
  
  if (isLoading) {
    <Grid>
      <NavBar />
      <Grid
        className={classes.root}
        container
        direction="column"
        justify="center"
        alignItems="center"
      >
        <Loading />
        </Grid>
      </Grid>
  }
  
  // redirect if the user hasn't set up an exchange yet
  !isLoading && hasSetupAnExchange === false && history("/coinbase");
  return (
    <Grid>
      <NavBar />
      <Grid
        className={classes.root}
        container
        direction="column"
        justify="center"
        alignItems="center"
      >
        {exchanges.length > 1 && (
          <Box>
            <Typography>Select Exchange</Typography>
            {exchanges.map((exchangeName) => (
              <Box key={exchangeName} display="inline" mr={2}>
                <Button
                  variant={
                    selectedExchange === exchangeName ? "contained" : "outlined"
                  }
                  onClick={() => {
                    setSelectedExchange(exchangeName);
                    localStorage.setItem("selectedExchange", exchangeName);
                  }}
                >
                  {exchangeName}
                </Button>
              </Box>
            ))}
          </Box>
        )}
        {hasSetupAnExchange && selectedExchange && (
          <Box p={2} style={{ width: "100%" }}>
            <OpenOrders selectedExchange={selectedExchange} />
          </Box>
        )}
        {hasSetupAnExchange && selectedExchange && (
          <Box p={2} style={{ width: "100%" }}>
            <Portfolio selectedExchange={selectedExchange} />
          </Box>
        )}
      </Grid>

      <Footer />
    </Grid>
  );
}
