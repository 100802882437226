import React, { useEffect, useState } from "react";

import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { Card, Input, Checkbox, FormControlLabel } from "@material-ui/core";
import { NavBar } from "../components/navbar";
import {
  setConfiguration,
  getTradingPairs,
  getUserConfiguration,
} from "../libs/api";
import { COLORS } from "../helpers/colors";
import { Loading } from "../components/loading";
import { getTickerInfo } from "../helpers/tickers";
import { usePrevious } from "../helpers/usePrevious";

interface configurationProps {
  [key: string]: {
    [key: string]: { enabled: boolean; maximum_daily_spend: string };
  };
}

const getTotalOrderAmount = (
  formData: configurationProps,
  selectedExchange: string
) => {
  if (!formData[selectedExchange]) {
    return 0;
  }

  const enabledTickers = Object.entries(formData[selectedExchange]).filter(
    ([, coin]) => coin.enabled
  );

  let totalCurrency = 0;
  for (const item in enabledTickers) {
    const [, coin] = enabledTickers[item];
    totalCurrency += parseFloat(coin.maximum_daily_spend);
  }
  return totalCurrency;
};

export default function Configuration() {
  const [selectedExchange, setSelectedExchange] = useState("coinbase");
  const prevSelectedExchange = usePrevious(selectedExchange);
  const hasSwitchedExchanges = prevSelectedExchange !== selectedExchange;

  const [tradingPairs, setTradingPairs] = useState<string[]>();
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [formData, setFormData] = useState<configurationProps>({});
  const [hasConnectionError, setHasConnectionError] = useState(false);
  const [searchText, setSearchText] = useState<string>("");
  const [isFilteringByEnabled, setIsFilteringByEnabled] = useState(false);

  const totalOrderAmount = selectedExchange && getTotalOrderAmount(formData, selectedExchange);

  useEffect(() => {
    async function fetchUserConfiguration() {
      try {
        const userConfiguration = await getUserConfiguration();
        if (userConfiguration["message"]) {
          console.error(userConfiguration.message);
          setHasConnectionError(true);
          return;
        }
        if (Object.entries(userConfiguration).length > 0) {
          setFormData({ ...userConfiguration });
        }
      } catch (e) {
        console.error("No config found for user");
      }
    }

    fetchUserConfiguration();
  }, []);

  useEffect(() => {
    if (!selectedExchange) {
      return;
    }

    if (hasSwitchedExchanges) {
      setTradingPairs(undefined);
      setHasSubmitted(false);
    }
    
    async function fetchTradingPairs() {
      if (!selectedExchange) {
        return;
      }
      
      const tradingPairs = await getTradingPairs(selectedExchange);
      if (tradingPairs.length > 0) {
        setTradingPairs(tradingPairs);
      } else {
        setHasConnectionError(true);
      }
    }

    

    if (!tradingPairs || hasSwitchedExchanges) {
      fetchTradingPairs();
    }
  }, [
    tradingPairs,
    setTradingPairs,
    setFormData,
    selectedExchange,
    hasSwitchedExchanges,
  ]);

  const getTickersToShow = (tradingPairs: string[]) => {
    if (selectedExchange && isFilteringByEnabled) {
      return tradingPairs.filter(
        (ticker) =>
          formData &&
          ticker in formData[selectedExchange] &&
          formData[selectedExchange][ticker].enabled
      );
    }

    return searchText?.length > 0
      ? tradingPairs?.filter(
          (ticker) =>
            ticker.toLowerCase().includes(searchText) ||
            getTickerInfo(ticker).name.toLowerCase().includes(searchText)
        )
      : tradingPairs;
  };

  let tickersToShow = tradingPairs;
  if (tradingPairs) {
    tickersToShow = getTickersToShow(tradingPairs);
  }

  return (
    <Grid container direction="column" justify="center" alignItems="center">
      <NavBar />
      <Box m={2}>
        {/* <Typography variant="h5">Select Exchange</Typography>
        <Typography>
          Make sure you set up your exchange account first!
        </Typography> */}
        {/* <Box mt={2}>
          {["gemini", "binance", "coinbase"].map((exchangeName) => (
            <Button
              variant={
                selectedExchange === exchangeName ? "contained" : "outlined"
              }
              onClick={() => {
                setSelectedExchange(exchangeName);
              }}
              style={{
                marginRight: "1rem"
              }}
            >
              {exchangeName}
            </Button>
          ))}
        </Box> */}
      </Box>
      {!tradingPairs && selectedExchange && <Loading />}
      {hasConnectionError && (
        <Typography variant="h3">Connection error</Typography>
      )}
      {hasSubmitted && (
        <Box mb={2} mt={2}>
          <Card>
            <Box p={2}>
              <Typography variant="h3">Saved!</Typography>
            </Box>
          </Card>
        </Box>
      )}
      {selectedExchange && (
        <Button
          variant="contained"
          color="primary"
          style={{
            // position: "fixed",
            // left: 0,
            top: "1rem",
          }}
          onClick={() => {
            setConfiguration({
              configuration: JSON.stringify({ ...formData }),
            }).then(() => {
              setHasSubmitted(true);
            });
          }}
        >
          Save
        </Button>
      )}
      {tickersToShow && (
        <Box mt={6}>
          <Box textAlign="center">
            <FormControlLabel
              control={
                <Input onChange={(e) => setSearchText(e.target.value)} />
              }
              label="Search"
            />
          </Box>
          <Box display="flex" mt={2} ml={3} flexWrap="wrap">
            <Box mt={2.5}>
              <Typography>Filter by</Typography>
            </Box>
            <Box ml={2} mt={2}>
              <Button
                variant="outlined"
                onClick={() => {
                  setSearchText("");
                  setIsFilteringByEnabled(true);
                }}
              >
                Enabled
              </Button>
            </Box>
            <Box ml={2} mt={2}>
              <Button variant="outlined" onClick={() => setSearchText("btc")}>
                Bitcoin
              </Button>
            </Box>
            <Box ml={2} mt={2}>
              <Button variant="outlined" onClick={() => setSearchText("eth")}>
                Ethereum
              </Button>
            </Box>
            <Box ml={2} mt={2}>
              <Button variant="outlined" onClick={() => setSearchText("ltc")}>
                Litecoin
              </Button>
            </Box>
            {(searchText.length > 0 || isFilteringByEnabled) && (
              <Box ml={2} mt={2}>
                <Button
                  variant="contained"
                  onClick={() => {
                    setSearchText("");
                    setIsFilteringByEnabled(false);
                  }}
                >
                  Clear
                </Button>
              </Box>
            )}
          </Box>
          {totalOrderAmount > 0 && (
            <Box p={2} mt={2}>
              <Typography>
                You need at least <strong>${totalOrderAmount}</strong> in your
                exchange account{" "}
                <a
                  href="https://en.wikipedia.org/wiki/Fiat_money"
                  target="_blank"
                  rel="noreferrer"
                >
                  as fiat
                </a>{" "}
                to initiate these orders.
              </Typography>
            </Box>
          )}
          <Box p={2}>
            <Typography>
              Make sure to read up on{" "}
              <a
                target="_blank"
                rel="noreferrer"
                href={
                  selectedExchange === "gemini"
                    ? "https://support.gemini.com/hc/en-us/articles/4401824250267-Are-there-trading-minimums-"
                    : "https://www.binance.com/en/trade-rule"
                }
              >
                order minimums
              </a>
              .
            </Typography>
          </Box>
          <Box style={{ display: "flex", width: "100%", flexWrap: "wrap" }}>
            {tickersToShow
              ?.sort((a, b) => (b > a ? -1 : 1))
              ?.sort((ticker) =>
                ticker !== getTickerInfo(ticker).name ? -1 : 1
              )
              .map((ticker: any) => (
                <Box m={2} key={ticker}>
                  <Card>
                    <Box padding={4}>
                      <Box style={{ backgroundColor: "#00000033" }} p={2}>
                        <Typography
                          variant="h3"
                          style={{ color: COLORS[ticker] }}
                        >
                          {ticker}
                        </Typography>
                        <Typography
                          variant="h5"
                          style={{ color: COLORS[ticker] }}
                        >
                          {getTickerInfo(ticker).name}
                        </Typography>
                      </Box>
                      <Box mb={2}>
                        <FormControlLabel
                          checked={
                            formData[selectedExchange]?.[ticker]?.enabled ===
                            true
                          }
                          control={<Checkbox defaultChecked />}
                          onChange={() => {
                            setFormData({
                              ...formData,
                              ...{
                                [selectedExchange]: {
                                  ...formData[selectedExchange],
                                  [ticker]: {
                                    ...formData[selectedExchange]?.[ticker],
                                    enabled:
                                      !formData[selectedExchange]?.[ticker]
                                        ?.enabled,
                                  },
                                },
                              },
                            });
                          }}
                          label="enabled"
                        />
                      </Box>
                      <Box mb={2}>
                        <label
                          htmlFor={`daily-spend-attr`}
                          style={{ marginRight: "1rem" }}
                        >
                          Maximum daily spend (in{" "}
                          {ticker.toLowerCase().includes("usd")
                            ? "USD"
                            : "Euros"}
                          )
                        </label>
                        <input
                          type="number"
                          min={0}
                          step="0.0001"
                          style={{
                            width: "50px",
                          }}
                          value={
                            formData[selectedExchange]?.[ticker]
                              ?.maximum_daily_spend
                          }
                          name={`daily-spend-attr`}
                          aria-label="daily-spend-attr"
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              ...{
                                [selectedExchange]: {
                                  ...formData[selectedExchange],
                                  [ticker]: {
                                    ...formData[selectedExchange]?.[ticker],
                                    maximum_daily_spend: parseFloat(
                                      e.target.value
                                    ),
                                  },
                                },
                              },
                            });
                          }}
                        />
                      </Box>
                    </Box>
                  </Card>
                </Box>
              ))}
          </Box>
        </Box>
      )}
    </Grid>
  );
}
