export const tickers: { [key: string]: any } = {
  btcusd: {
    name: "bitcoin (usd)",
    ticker: "btcusd",
  },
  btceur: {
    name: "bitcoin (eur)",
    ticker: "btceur",
  },
  ethusd: {
    name: "ethereum (usd)",
    ticker: "ethusd",
  },
  etheur: {
    name: "ethereum (eur)",
    ticker: "etheur",
  },
  ltcusd: {
    name: "litecoin",
    ticker: "ltcusd",
  },
  batusd: {
    name: "basic attention token",
    ticker: "batusd",
  },
  linkusd: {
    name: "chainlink",
    ticker: "linkusd",
  },
  daiusd: {
    name: "dai",
    ticker: "daiusd",
  },
  storjusd: {
    name: "storjcoin",
    ticker: "storjusd",
  },
  filusd: {
    name: "filecoin",
    ticker: "filusd"
  }
};

export const getTickerInfo = (ticker: string) => {
    if (ticker in tickers) {
        return tickers[ticker];
    }

    return {
        name: ticker,
        ticker: ticker
    };
}