import React, { useState } from "react";

import { makeStyles } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";

import { Card, Checkbox, FormControlLabel, Input } from "@material-ui/core";

import { setApiKey } from "../libs/api";
import { NavBar } from "../components/navbar";
import { COLORS } from "../helpers/colors";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {},
  title: {
    textAlign: "center",
  },
  session: {
    width: "80vw",
    overflow: "auto",
    overflowWrap: "break-word",
    fontSize: "16px",
  },
  hero: {
    width: "100%",
    background: "#333",
  },
}));

export default function Binance() {
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [formData, setFormData] = useState({
    key: "",
    secret: "",
  });
  const classes = useStyles();
  const history = useNavigate();
  
  return (
    <Grid container>
      <NavBar />
      <Grid
        className={classes.root}
        container
        direction="column"
        justify="center"
        alignItems="center"
      >
        <Box m={2}>
          <Typography className={classes.title} variant="h3">
            Binance Setup
          </Typography>
        </Box>
        <Box m={2}>
          <Box mb={2}>
            {hasSubmitted ? (
              <Box mb={4}>
                <Card>
                  <Box p={4}>
                    <Typography variant="h3" style={{ color: COLORS.primary }}>
                      Got it! Thanks.
                    </Typography>
                    <Typography variant="h4">
                      Next, choose which coins you want to buy.
                    </Typography>
                    <Box mt={2}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => history("/configuration")}
                      >
                        Coin Configuration
                      </Button>
                    </Box>
                  </Box>
                </Card>
              </Box>
            ) : (
              <Box p={2} mb={2} maxWidth="500px">
                <Card>
                  <Box padding={4}>
                    <Box mb={2}>
                      <label
                        htmlFor={`key-attr`}
                        style={{ marginRight: "1rem" }}
                      >
                        API Key
                      </label>
                      <Input
                        data-test="api-key-input"
                        name={`key-attr`}
                        aria-label="key attr"
                        onChange={(e) =>
                          setFormData({ ...formData, key: e.target.value })
                        }
                      />
                    </Box>
                    <Box mb={2}>
                      <label
                        htmlFor={`secret-attr`}
                        style={{ marginRight: "1rem" }}
                      >
                        API Secret
                      </label>
                      <Input
                        data-test="api-secret-input"
                        name={`secret-attr`}
                        aria-label="secret attr"
                        onChange={(e) =>
                          setFormData({ ...formData, secret: e.target.value })
                        }
                      />
                    </Box>

                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() =>
                        setApiKey({
                          key: formData.key,
                          secret: formData.secret,
                          user: localStorage.getItem("userId") || "",
                          exchangeName: "binance",
                        }).then(() => {
                          setHasSubmitted(true);
                        })
                      }
                    >
                      Save
                    </Button>
                  </Box>
                </Card>
              </Box>
            )}

            <Typography variant="h4">How to find your keys</Typography>

            <Box>
              <Grid container direction="column" justify="center">
                <Box mt={2}>
                  <Typography variant="h5">
                    Go to{" "}
                    <a
                      rel="noreferrer"
                      target="_blank"
                      href="https://www.binance.com/en/support/faq/360002502072"
                    >
                      Binance's API settings page.
                    </a>
                  </Typography>
                </Box>

                <FormControlLabel
                  control={<Checkbox />}
                  label="Set up your API key settings to just allow Trading (see image below)"
                />
                <FormControlLabel
                  control={<Checkbox />}
                  label="Leave the rest of the form as default"
                />
                <FormControlLabel
                  control={<Checkbox />}
                  label="Copy & paste the keys into the boxes above, and hit Save."
                />
              </Grid>
              <Box mt={2}>
                <img
                  alt="binance api with Trade permissions box checked"
                  src="images/binance-api-setup.png"
                  style={{ maxWidth: "80%", maxHeight: "700px" }}
                ></img>
              </Box>
            </Box>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}
